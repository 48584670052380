import { envDef, firebaseConfig } from 'src/environments/env.default';

export const environment = {
  ...envDef,
  prefix: 'sindicato',
  version: '1.0.244',
  urlAPI: 'https://sindicato.prod.api.gestor.ladder.dev.br',
  urlBase: 'sindicato.app',
  CrossDomainStorageOrigin: 'https://sindicato.prod.api.gestor.ladder.dev.br',
  firebase: firebaseConfig
};
